import React from "react";
import aparna from "../../images/aparna.jpg";
import urmila from "../../images/urmila.jpg";
import somya from "../../images/somya.jpg";
import saya from "../../images/saya.jpg";
import swara from "../../images/swara.jpg";

import shashank from "../../images/shashank.jpg";

import { BsLinkedin } from "react-icons/bs";

const AboutTeam = () => {
  return (
    <>
      <div className="about-testo">
        <div className="container">
          <div className="about-philospy-wrapper">
            <h1>Meet Our Team </h1>
            <div className="about-team">
              <div className="about-team-wrapper">
                <img src={aparna} alt="" />
                <h3>Aparna Rajeev</h3>
                <h6>Executive Search</h6>
                <a href=" https://www.linkedin.com/in/aparna-rajeev-3aa5bb63/">
                  <BsLinkedin />
                </a>
              </div>
              <div className="about-team-wrapper">
                <img src={urmila} alt="" />
                <h3>Urmila Hejib</h3>
                <h6>Senior Executive – HR & Admin</h6>
                <a href="https://www.linkedin.com/in/urmila-hejib-129557256/">
                  <BsLinkedin />
                </a>
              </div>
              <div className="about-team-wrapper">
                <img src={shashank} alt="" />
                <h3>Shashank S</h3>
                <h6>Lead Executive Search</h6>
                <a href="https://www.linkedin.com/in/shashank-s-664028183/">
                  <BsLinkedin />
                </a>
              </div>
              <div className="about-team-wrapper">
                <img src={somya} alt="" />
                <h3>Sowmyashree </h3>
                <h6>Manager-Executive Search</h6>
                <a href=" https://www.linkedin.com/in/somyashree-b-28508a9b/">
                  <BsLinkedin />
                </a>
              </div>
              <div className="about-team-wrapper">
                <img src={swara} alt="" />
                <h3>Swara Naik</h3>
                <h6>Content and Growth Specialist</h6>
                <a href="https://www.linkedin.com/in/swara-naik-81885b228/">
                  <BsLinkedin />
                </a>
              </div>
              <div className="about-team-wrapper">
                <img src={saya} alt="" />
                <h3>Sayantani Dutta</h3>
                <h6>Manager Client Acquisition and Growth</h6>
                <a href=" https://www.linkedin.com/in/sayantani-dutta-a6283994/">
                  <BsLinkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTeam;
